import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import {
  receivePrice,
  receivePriceHistory,
  setChartSymbolAndChartTag,
} from "./redux/priceAction";
import {
  ConnectionType,
  QUOTES_SERVER_PORT,
  WEBSOCKET_NUM_RECONN_ATTEMPTS,
  WEBSOCKET_HEART_BEAT_INTERVAL_MS,
  RequestCode, ResponseCode
} from "./constants/global";
import { getCandleConfig } from "./helpers";
import { setIsAuth, updateEarliestTimestamp } from "./redux/websocketAction";


const WebSocket = (props) => {
  const dispatch = useDispatch();
  const {jsonMessage, isAuth} = useSelector(state => state.socket);
  const { quotesServer, token: login, sToken: password, symbol, chartTag } = props;
  const connectionType = ConnectionType.CHART_VIEW;
  const socketUrl = `ws://${quotesServer}:${QUOTES_SERVER_PORT}`;
  
  const intervalID = useRef(null);

  useEffect(() => {
    // console.log(symbol, chartTag);
    dispatch(setChartSymbolAndChartTag({ symbol, chartTag }));
  }, [symbol, chartTag]);

  const onClose = useCallback(() => {
    console.log("websocket closed", quotesServer);
    if (intervalID.current) {
      clearInterval(intervalID.current);
      intervalID.current = null;
    }
  }, [quotesServer]);

  const { readyState, sendJsonMessage, lastJsonMessage } = useWebSocket(
    socketUrl,
    {
      onClose,
      onError: (e) => console.log("websocket error", quotesServer, e),
      shouldReconnect: (e) => true,
      share: true,
      reconnectAttempts: WEBSOCKET_NUM_RECONN_ATTEMPTS,
    },
    login !== null
  );

  const [socket, setSocket] = useState({
    login: login,
    password: password,
    isLogined: false,
    token: null,
    hasError: !lastJsonMessage?.state,
    errorMsg: !lastJsonMessage?.state ? lastJsonMessage?.err_msg : "",
    message: "",
  });

  const sendHartbeat = useCallback(() => {
    sendJsonMessage({ req_code: 0 });
  }, [sendJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  useEffect(() => {
    console.log("SERVER IP:", quotesServer, "CONNECTION STATUS:", connectionStatus);
    switch (readyState) {
      case ReadyState.OPEN:
        if (login && password) {
          sendJsonMessage({
            req_code: RequestCode.REQ_LOGIN,
            id: login,
            password: password,
            type: connectionType,
          });
        }
        if (intervalID.current === null) {
          intervalID.current = setInterval(sendHartbeat, WEBSOCKET_HEART_BEAT_INTERVAL_MS);
        }
        break;

      case ReadyState.CLOSED:
        if (intervalID.current) {
          clearInterval(intervalID.current);
          intervalID.current = null;
        }
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readyState, quotesServer]);

  useEffect(() => {
    if (lastJsonMessage) {
      switch (lastJsonMessage.res_code) {
        case ResponseCode.LOGIN:
          if (lastJsonMessage.state) {
            dispatch(setIsAuth(true));
            setSocket({
              isLogined: true,
              token: lastJsonMessage.hashcode,
              client: lastJsonMessage.name,
              hasError: !!lastJsonMessage.state,
              errorMsg: lastJsonMessage.state ? lastJsonMessage.err_msg : "",
            });
            sendJsonMessage({
              req_code: 5,
              id: login,
              password: password,
              type: connectionType,
            });
            // console.log(lastJsonMessage.server_time, lastJsonMessage.timezone);
          } else {
            dispatch(setIsAuth(false));
            if (login && password) {
              sendJsonMessage({
                req_code: RequestCode.REQ_LOGIN,
                id: login,
                password: password,
                type: connectionType,
              });
            }
            setSocket({
              login: null,
              password: null,
              isLogined: false,
              token: null,
              hasError: !lastJsonMessage.state,
              errorMsg: !lastJsonMessage.state ? lastJsonMessage.err_msg : "",
            });
          }
          break;
        case ResponseCode.MARKET_PRICE:
          // Only the desired symbol price will be received for dispatch
          if (lastJsonMessage.symbol === symbol) {
            delete lastJsonMessage.res_code;
            dispatch(receivePrice(lastJsonMessage));
          }
          break;
        case ResponseCode.PRICE_HISTORY:
          // console.log(lastJsonMessage);
          dispatch(updateEarliestTimestamp(lastJsonMessage.from));
          dispatch(receivePriceHistory(lastJsonMessage.array));
          break;
        case ResponseCode.ACCOUNT_INFO:
          break;
        case 5:
          break;
        case ResponseCode.OPEN_POSITIONS:
          break;
        case ResponseCode.SYMBOL_LIST:
          // dispatch(receiveSymbols(lastJsonMessage.array));
          break;
        default:
          console.log(lastJsonMessage);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastJsonMessage]);

  // useEffect(() => {
  //   //     console.log("history.length", history.length);
  //   //     if(history.length > 0)
  //   //         return;
  //   //     const interval = getCandleConfig('M5');
  //   //     const nowDate = Date.now()
  //   //     // const newFrom = Number.parseInt((nowDate.getDate() - 1) / 1000 );
  //   //     const now = Number.parseInt(Date.now() / 1000, 10);
  //   //     // const from = ;
  //   //     // console.log("from", from, now);

  //   //     sendJsonMessage({
  //   //         symbol: symbol,
  //   //         req_code: 3,
  //   //         from: now - numberCandlesPerFrame * 12600 * interval > 0
  //   //         ? now - numberCandlesPerFrame * 12600 * interval
  //   //         : 0,
  //   //         to: -1,
  //   //         interval: interval,
  //   if (isAuth && chartTag && symbol) {
  //     const now = Number.parseInt(Date.now() / 1000, 10);
  //     const { interval, timeFrame: candleNum } = getCandleConfig(chartTag);
  //     const tempCandleNum = 1 * candleNum;
  //     const socketMsg = {
  //       symbol: symbol,
  //       req_code: 3,
  //       from: now - tempCandleNum * 1,
  //       to: now,
  //       interval: interval,
  //     }
      
  //     // console.log('PRICE HISTORY REQUEST BODY:', socketMsg);
  //     // sendJsonMessage(socketMsg);
  //   }
  // }, [symbol, chartTag, isAuth]);

  useEffect(() => {
    if (sendJsonMessage && jsonMessage) {
      sendJsonMessage(jsonMessage);
      // console.log("socket message", jsonMessage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonMessage]);

  return <></>;
};

export default WebSocket;
