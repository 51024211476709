/// Each reducer has its own types, so a prefix to easy indicates to which reducer this type belongs to

// Websocket reducer types, uses SOCKET_ as prefix
export const SOCKET_SEND_JSON_MESSAGE = "SET_WEBSOCKET_MESSAGE_TO_SEND";
export const SOCKET_IS_AUTH = "SET_IS_AUTH";
export const SOCKET_UPDATE_EARLIEST_TIMESTAMP = "UPDATE_HIST0RY_EARLIEST_TIMESTATMP";
export const SOCKET_IS_REQUEST_IN_PROGRESS = "SET_IS_REQUEST_IN_PROGRESS";

// Price reducer types, uses PRICE_ as prefix
export const PRICE_DATA_RECEIVE = "MARKET_PRICE_RECEIVE";
export const PRICE_HISTORY_RECEIVE = "PRICE_HISTORY_RECEIVE";
export const PRICE_CHART_SYMBOL = "Price_Chart_Symbol";
