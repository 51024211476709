import { createChart, CrosshairMode } from "lightweight-charts";
import React, { useCallback, useEffect, useRef } from "react";
import {
  BOLLINGER_DEFAULT_PERIOD, BOLLINGER_DEFAULT_STD,
  MACD_COLORS, MACD_FAST_PERIOD, MACD_SIGNAL_PERIOD, MACD_SLOW_PERIOD,
  MACD_SOURCE,
  MOVING_AVERAGE_DEFAULT_PERIOD,
  ONE_MINUTE
} from "../constants/chart-defaults";
import {
  DEFAULT_PERSISION,
  RequestCode
} from "../constants/global";
import { getCandleConfig, timeToLocal } from "../helpers";
import useWindowDimensions from "../hooks/WindowDimensions";
import { bollinger } from "../indicators/bollinger";
import { MACD } from "../indicators/macd";
import { calculateMovingAverageSeriesData } from "../indicators/movingAverageSimple";
import { useDebounceSelector } from "../redux/debounce";
import { ChartLegenedComponent } from "./ChartLegend";
import { useDispatch, useSelector } from "react-redux";
import { setIsRequestInProgress, setJsonMessageToSend } from "../redux/websocketAction";


export const ChartComponent = (props) => {
  const dispatch = useDispatch();
  const chartData = useDebounceSelector(
    (state) => state.price,
    (a, b) => a === b,
    20
  );
  const { isAuth, earliestTimestamp, isRequestInProgress } = useSelector(state => state.socket);
  const { price, history, symbol: symbolConfig } = chartData;
  const { chartOptions, chartTag, symbol } = props;
  const { height } = useWindowDimensions();

  const isRequestInProgressRef = useRef(false);
  const savedScrollPositionRef = useRef(null);
  const chartContainerRef = useRef();
  const chartRef = useRef(null);
  const candlestickSeriesRef = useRef(null);
  const digits = useRef(DEFAULT_PERSISION);
  const indicators = useRef({
    movingAvg: {
      label: "Moving Average Simple", enable: false, func: calculateMovingAverageSeriesData,
      ref: null, data: [], seriesType: 'line',
      params: { period: MOVING_AVERAGE_DEFAULT_PERIOD },
      seriesOptions: { color: 'rgb(242, 142, 44)', lineWidth: 1 }
    },
    bollinger: {
      label: "Bollinger", enable: false, func: bollinger, seriesType: { ml: 'line', bl: 'line', tl: 'line' },
      mlRef: null, blRef: null, tlRef: null, data: { ml: [], bl: [], tl: [] },
      params: { period: BOLLINGER_DEFAULT_PERIOD, std: BOLLINGER_DEFAULT_STD },
      seriesOptions: {
        tl: { color: '#2962FF', lineWidth: 1 },
        ml: { color: 'rgb(87, 225, 90)', lineWidth: 1 },
        bl: { color: '#2962FF', lineWidth: 1 },
      }
    },
    macd: {
      label: "MACD", enable: false, func: MACD,
      MACDRef: null, signalEMARef: null, seriesType: { signalEMA: 'line', MACD: 'histogram' },
      data: { MACD: [], fastEMA: [], slowEMA: [], signalEMA: [], histogram: [] },
      params: {
        fastPeriod: MACD_FAST_PERIOD,
        slowPeriod: MACD_SLOW_PERIOD,
        signalPeriod: MACD_SIGNAL_PERIOD,
        applyTo: MACD_SOURCE,
        colors: MACD_COLORS
      },
      priceOption: {
        scaleMargins: {
          top: 0.8,
          bottom: 0.005
        }
      },
      seriesOptions: {
        MACD: {
          priceScaleId: 'macd',
          color: '#2962FF',
          lineWidth: 0.9,
          priceLineVisible: false,
          priceFormat: {
            type: 'price',
            precision: 6
          }
        },
        signalEMA: {
          priceScaleId: 'macd',
          color: '#2962FF',
          lineWidth: 0.9,
          priceLineVisible: false,
          priceFormat: {
            type: 'volume',
            precision: 6
          }
        }
      }
    }
  });
  const indicatorList = useRef(
    JSON.parse(localStorage.getItem('indicatorList')) || []);
  const removedIndicatorList = useRef([]);

  const enableIndicator = useCallback((_indicatorList = []) => {
    indicatorList.current = [..._indicatorList];

    indicatorList.current.forEach(indicatorName => {
      indicators.current[indicatorName].enable = true;
      indicators.current[indicatorName].func(candlestickSeriesRef.current.data(), indicators.current[indicatorName].data)

      Object
        .keys(indicators.current[indicatorName])
        .filter(function (k) { return /ref/.test(k.toLowerCase()); })
        .forEach(function (ref) {
          if (indicators.current[indicatorName][ref] == null) {
            if (ref === 'ref') {
              indicators.current[indicatorName][ref] = chartRef.current.addLineSeries(indicators.current[indicatorName].seriesOptions);
              indicators.current[indicatorName][ref].setData(indicators.current[indicatorName].data);
            }
            else {
              const innerDataName = ref.split('Ref')[0];

              if (indicators.current[indicatorName].seriesType[innerDataName] === 'line') {
                indicators.current[indicatorName][ref] = chartRef.current.addLineSeries(indicators.current[indicatorName].seriesOptions[innerDataName]);
              }
              if (indicators.current[indicatorName].seriesType[innerDataName] === 'histogram') {
                indicators.current[indicatorName][ref] = chartRef.current.addHistogramSeries(indicators.current[indicatorName].seriesOptions[innerDataName]);
              }
              if (indicators.current[indicatorName].hasOwnProperty('priceOption')) {
                chartRef.current.priceScale("right").applyOptions({
                  borderColor: "#71649C",
                  autoScale: true, // disables auto scaling based on visible content
                  scaleMargins: {
                    top: 0.1,
                    bottom: 0.3,
                  },
                });
                indicators.current[indicatorName][ref].priceScale().applyOptions(indicators.current.macd.priceOption);
              }
              indicators.current[indicatorName][ref].setData(indicators.current[indicatorName].data[innerDataName]);
            }
          }
        });
    });
  }, [indicators.current, indicatorList.current])

  const disableIndicator = useCallback((_removedIndicatorList = []) => {
    removedIndicatorList.current = [..._removedIndicatorList];
    removedIndicatorList.current.forEach(indicatorName => {
      indicators.current[indicatorName].enable = false;
      Object
        .keys(indicators.current[indicatorName])
        .filter(function (k) { return /ref/.test(k.toLowerCase()); })
        .forEach(function (ref) {
          if (indicators.current[indicatorName][ref]) {
            chartRef.current.removeSeries(indicators.current[indicatorName][ref]);
            indicators.current[indicatorName][ref] = null;
          }
        });
    });
  }, [indicators.current, removedIndicatorList.current])

  const requestEarlierHistory = useCallback(
    () => {
      if (isAuth && chartTag && symbol) {
        const to = earliestTimestamp < -1 ? Number.parseInt(Date.now() / 1000, 10) : earliestTimestamp;
        const { interval, timeFrame: candleNum } = getCandleConfig(chartTag);
        const tempCandleNum = 1 * candleNum;
        const socketMsg = {
          symbol: symbol,
          req_code: RequestCode.REQ_PRICE_HISTORY,
          from: to - tempCandleNum * 1,
          to: to,
          interval: interval,
        }
        dispatch(setJsonMessageToSend(socketMsg));
      }
    },
    [earliestTimestamp]
  );

  const getCandles = useCallback(
    (numberBarsToLoad = 50) => {
      let cdlValues = [];
      if (history && history.length) {       
        // let startIdx = history.length - numberBarsToLoad;
        // console.log("history.length - numberBarsToLoad", history.length - numberBarsToLoad);
        for (let idx = 0; idx < history.length; idx++) {
          const item = history.at(idx);
          const zonedDate = timeToLocal(item.x);
          cdlValues.push({
            time: zonedDate,
            ...item,
            marker:
              "High: " +
              item.high.toFixed(digits.current) +
              "\nOpen: " +
              item.open.toFixed(digits.current) +
              "\nClose: " +
              item.close.toFixed(digits.current) +
              "\n  Low: " +
              item.low.toFixed(digits.current),
          });
        };
      }
      return cdlValues;
    },
    [history, price]
  );

  useEffect(() => {
    const handleResize = () => {
      chartRef.current.applyOptions({
        width: chartContainerRef.current.clientWidth,
        height: height,
      });
    };
    const data = getCandles(50);

    chartRef.current = createChart(chartContainerRef.current, {
      autoSize: false,
      ...chartOptions,
      width: chartContainerRef.current.clientWidth,
      height: height,
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
      },
    });

    candlestickSeriesRef.current = chartRef.current.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
      priceFormat: {
        type: 'price',
        precision: digits.current,
        minMove: 1 / (10 ** digits.current)
      },
    });

    // Setting the border color for the horizontal axis
    chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().scrollToPosition(2);
    chartRef.current.timeScale().applyOptions({
      borderColor: "#71649C",
      barSpacing: 10,
    });
    chartRef.current
      .timeScale()
      .subscribeVisibleLogicalRangeChange((logicalRange) => {
        if (!isRequestInProgressRef.current && logicalRange.from < 10) {
          dispatch(setIsRequestInProgress(true));
          isRequestInProgressRef.current = true;
          const currentScrollPosition = chartRef.current.timeScale().scrollPosition();
          if (currentScrollPosition) {
            savedScrollPositionRef.current = currentScrollPosition;
          }
          // load more data
          requestEarlierHistory();          
          // setTimeout(() => {
            // isRequestInProgressRef.current = false;
          // }, 1000); // add a loading delay
        }
    });
    chartRef.current.priceScale("right");

    // Setting the border color for the vertical axis
    chartRef.current.priceScale("right").applyOptions({
      borderColor: "#71649C",
      autoScale: true, // disables auto scaling based on visible content
      scaleMargins: {
        top: 0.1,
        bottom: 0.2,
      },
    });

    window.addEventListener("resize", handleResize);
    candlestickSeriesRef.current.setData(data);

    indicatorList.current.forEach(indicatorName => {
      indicators.current[indicatorName].enable = true;
      indicators.current[indicatorName].func(candlestickSeriesRef.current.data(), indicators.current[indicatorName].data)
      Object
        .keys(indicators.current[indicatorName])
        .filter(function (k) { return /ref/.test(k.toLowerCase()); })
        .forEach(function (ref) {
          if (ref === 'ref') {
            indicators.current[indicatorName][ref] = chartRef.current.addLineSeries(indicators.current[indicatorName].seriesOptions);
            indicators.current[indicatorName][ref].setData(indicators.current[indicatorName].data);
          }
          else {
            const innerDataName = ref.split('Ref')[0];

            if (indicators.current[indicatorName].seriesType[innerDataName] === 'line') {
              indicators.current[indicatorName][ref] = chartRef.current.addLineSeries(indicators.current[indicatorName].seriesOptions[innerDataName]);
            }
            if (indicators.current[indicatorName].seriesType[innerDataName] === 'histogram') {
              indicators.current[indicatorName][ref] = chartRef.current.addHistogramSeries(indicators.current[indicatorName].seriesOptions[innerDataName]);
            }
            if (indicators.current[indicatorName].hasOwnProperty('priceOption')) {
              chartRef.current.priceScale("right").applyOptions({
                borderColor: "#71649C",
                autoScale: true, // disables auto scaling based on visible content
                scaleMargins: {
                  top: 0.1,
                  bottom: 0.3,
                },
              });
              indicators.current[indicatorName][ref].priceScale().applyOptions(indicators.current.macd.priceOption);
            }
            indicators.current[indicatorName][ref].setData(indicators.current[indicatorName].data[innerDataName]);
          }
        });
    });

    return () => {
      window.removeEventListener("resize", handleResize);
      chartRef.current.remove();
    };
  }, [history, chartOptions, symbolConfig, height]);

  useEffect(() => {
    candlestickSeriesRef.current.applyOptions({
      priceFormat: {
        type: 'price',
        precision: digits.current,
        minMove: 1 / (10 ** digits.current)
      },
    });
  }, [digits.current])

  useEffect(() => {
    if (price && history?.length) {
      const item = history[history.length - 1];
      digits.current = price?.digits ? price.digits : DEFAULT_PERSISION;
      candlestickSeriesRef.current.update({
        time: timeToLocal(item.x),
        ...item,
        marker:
          "High: " +
          item.high.toFixed(digits.current) +
          "\nOpen: " +
          item.open.toFixed(digits.current) +
          "\nClose: " +
          item.close.toFixed(digits.current) +
          "\n  Low: " +
          item.low.toFixed(digits.current),
      });
    }
  }, [price, history]);

  const cdlValues = candlestickSeriesRef.current?.data() || [];
  useEffect(() => {
    if (cdlValues.length) {
      Object.keys(indicators.current).forEach(indicatorName => {
        if (indicators.current[indicatorName].enable) {
          indicators.current[indicatorName].func(cdlValues, indicators.current[indicatorName].data);

          Object
            .keys(indicators.current[indicatorName])
            .filter(function (k) { return /ref/.test(k.toLowerCase()); })
            .forEach(function (ref) {
              if (ref === 'ref') {
                indicators.current[indicatorName][ref].update(indicators.current[indicatorName].data[cdlValues.length - 1]);
              }
              else {
                const innerDataName = ref.split('Ref')[0];
                indicators.current[indicatorName][ref].update(indicators.current[indicatorName].data[innerDataName][cdlValues.length - 1]);
              }
            });
        }
      });
    }
  }, [cdlValues]);

  useEffect(() => {
    if (history.length) {
      const data = getCandles();
      // console.log(data, history?.length);
      candlestickSeriesRef.current.setData(data);
      if (savedScrollPositionRef.current) {
        chartRef.current.timeScale().scrollToPosition(savedScrollPositionRef.current);
      }
      setTimeout(() => {
        isRequestInProgressRef.current = false
      }, 1000);
    }
  }, [history]);

  useEffect(() => {
    if (isAuth && chartTag && symbol && history?.length === 0) {
      const now = Number.parseInt(Date.now() / 1000, 10);
      const { interval, timeFrame } = getCandleConfig(chartTag);
      const socketMsg = {
        symbol: symbol,
        req_code: RequestCode.REQ_PRICE_HISTORY,
        from: now - timeFrame ,
        to: -1,
        interval: interval,
      }
      
      dispatch(setJsonMessageToSend(socketMsg));
    }
  }, [isAuth, symbol, chartTag]);

  return (
    <div ref={chartContainerRef}>
      <ChartLegenedComponent
        enableIndicatorCallback={enableIndicator}
        disableIndicatorCallback={disableIndicator}
        fitContent={() => {
          chartRef.current.timeScale().resetTimeScale();
          chartRef.current.timeScale().scrollToPosition(2);
          chartRef.current.priceScale("right").applyOptions({
            borderColor: "#71649C",
            autoScale: true, // disables auto scaling based on visible content
            scaleMargins: {
              top: 0.1,
              bottom: 0.2,
            },
          });
        }}
        symbol={symbol}
        price={price ? price.bid.toFixed(digits.current) : 0}
      />
    </div>
  );
};
