import { SOCKET_IS_AUTH, SOCKET_IS_REQUEST_IN_PROGRESS, SOCKET_SEND_JSON_MESSAGE, SOCKET_UPDATE_EARLIEST_TIMESTAMP } from "./types";

const initialState = {
    isAuth: false,
    jsonMessage: null,
    earliestTimestamp: -1,
    isRequestInProgress: false,
  };

export default (state = initialState, action) => {
    let payload = action.payload;
    switch (action.type) {
        case SOCKET_SEND_JSON_MESSAGE: {    
            return { ...state, jsonMessage: payload };
        }
        case SOCKET_IS_AUTH: {
            return { ...state, isAuth: payload };
        }
        case SOCKET_UPDATE_EARLIEST_TIMESTAMP: {
            return { ...state, earliestTimestamp: payload };
        }
        case SOCKET_IS_REQUEST_IN_PROGRESS:{
            return { ...state, isRequestInProgress: payload };
        }
            default:
            return state;
    };
};